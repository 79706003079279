import React, { useState, useEffect } from 'react'
import { Spin, Modal, Input, Table } from 'antd'
import './index.css'
import intl from "react-intl-universal";
import { CloseOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const errorPhoto = require('@/images/errorPhoto.png')
export default function OrderDetailModal(props) {
  const [modifyOrderDetailList, setModifyOrderDetailList] = useState(props.modifyOrderDetailList);
  useEffect(() => {
    setModifyOrderDetailList(props.modifyOrderDetailList);
  }, [props.modifyOrderDetailList]);
  const orderDetail = props.orderDetailList && props.orderDetailList.item ? props.orderDetailList.item : false
  const payStatus = props.orderDetailList && props.orderDetailList.payStatus ? props.orderDetailList.payStatus : false
  const listCars = props.orderDetailList ? props.orderDetailList : false
  const columns = [
    {
      title: intl.get("serialNumber"),
      key: 'No.',
      width: 65,
      render: (text, record, index) => <a>{index + 1}</a>,
    },
    {
      title: intl.get("productImage"),
      width: 112,
      dataIndex: 'mainPicture',
      align: 'center',
      render: (item) => <img onError={(e) => { e.target.onerror = null; e.target.src = errorPhoto }} className='img_sty' src={item} alt="" />
    },
    {
      title: intl.get('productName'),
      width: 300,
      dataIndex: 'produceName',
      align: 'left',
      render: (item) => <div className='purchasedGoods_produceName_sty' >{item}</div>
    },
    {
      title: intl.get('price'),
      width: 150,
      align: 'right',
      // className: 'font-size-20',
      render: (item) =>
        <div>
          <div
            className="specialPrice"
            style={{ display: item.specialStatus == 0 ? "none" : "" }}>
            {props.symbol} {item.specialPrice ? item.specialPrice.toFixed(2) : ""}
            <div>{intl.get("specialOffers")}</div>
          </div>
          <div className='font-size-24' style={{ display: item.specialStatus == 0 ? "" : "none" }}>
            {props.symbol} {item.producePrice ? item.producePrice.toFixed(2) : ""}
          </div>
        </div >
    },
    {
      title: intl.get('quantity'),
      width: 150,
      align: 'center',
      dataIndex: '',
      // className: 'font-size-20'
      render: (text, record, index) => {
        if (listCars.payStatus == 0 && listCars.sendGoodsStatus == 0) {
          return <div className='col_lamination'>
            <div className='inputGroup'>
              <div >
                <button onClick={() => props.inputOrderDetailList(0, index)} className='padding_10'>
                  <i className="fa fa-angle-left"></i>
                </button>
              </div>
              <div className='inputNumber'>
                <input
                  type="number"
                  name="quantity"
                  value={text.orderQty}
                  onInput={(e) => props.inputOrderDetailList(2, index, e.target.value)
                  }
                  // onFocus={() => this.onFucsInputQty(text.quantity)}
                  // onBlur={() => this.inputQty(text)}
                  className="input-number-style"
                />
              </div>
              <div >
                <button onClick={() => props.inputOrderDetailList(1, index)} className='padding_10'><i className="fa fa-angle-right"></i></button></div>

            </div>
            <div className='remark_style' >{intl.get("remark")}:</div>
          </div>
        } else {
          return <div className='col_lamination'>
            <div className='font-size-20' >{text.orderQty}</div>
            <div className='remark_style' >{intl.get("remark")}:</div>
          </div>

        }
      }
    },
    {
      title: intl.get("amount"),
      align: 'right',
      render: (text, record, index) =>
        // <div>
        //     <div
        //       style={{ display: item.specialStatus == 0 ? "none" : "" }}
        //       className={item.specialStatus == 0 ? "" : "specialPrice"}>

        //       {props.symbol} {item.tolalSpecialPrice}
        //     </div>
        //     <h2
        //       style={{ display: item.specialStatus == 0 ? "" : "none" }}
        //     >
        //       {props.symbol} {item.tolalAmount}
        //     </h2>
        //   </div >
        <div className='col_lamination'>
          <div className='font-size-24' >{"$ " + (text.producePrice * text.orderQty).toFixed(2)}</div>
          {listCars.payStatus == 0 && listCars.sendGoodsStatus == 0 ? <Input
            style={{ backgroundColor: "#f2f2f2", border: "#f2f2f2" }}
            value={text.detailRemark} onChange={e => props.inputOrderDetailRemarkList(index, e)} /> :
            <div>{text.detailRemark}</div>}

        </div>

    },


  ]
  if (listCars.payStatus == 0 && listCars.sendGoodsStatus == 0) {
    columns.push({
      title: intl.get("operation"),
      align: 'center',
      width: 80,
      render: (text, record, index) =>
        <CloseOutlined onClick={() => props.delectOrderDetailList(index)} style={{ color: '#EE1D23' }} />
    },)
  }
  // onUseAnotherAddressHandle
  const tableList = modifyOrderDetailList ? [...modifyOrderDetailList] : [];
  const totalAmountFun = () => {
    if (props.modifyOrderDetailState) {
      let totalAmount = 0
      let nomaltotalAmount = 0
      let discAmount = 0
      tableList.map(item =>
        item.specialStatus == 1 ? discAmount += (item.specialPrice * item.orderQty) : nomaltotalAmount += (item.producePrice * item.orderQty)
      )

      if (listCars && listCars.clientDisc && typeof listCars.clientDisc == "number" && typeof totalAmount == "number") {
        totalAmount = discAmount + nomaltotalAmount * (100 - listCars.clientDisc) * 0.01
      }
      else {
        totalAmount = discAmount + nomaltotalAmount;
      }

      let numb = Math.ceil(totalAmount * 100) / 100
      if (numb == 0) {
        return "0.00"
      } else {
        return parseFloat(numb).toFixed(2)
      }
    } else {
      if (listCars && listCars.amtValue) {
        let amtValue = listCars.amtValue;
        if (typeof amtValue == "number") {
          return parseFloat(amtValue).toFixed(2)
        } else {
          return amtValue
        }

      } else {
        return "0.00"
      }
    }
  }
  const totalDiscountAmountFun = () => {
    if (props.modifyOrderDetailState) {
      let totalAmount = 0
      tableList.map(item => item.specialStatus == 1 ? totalAmount += (item.specialPrice * item.orderQty) : "")
      if (totalAmount == 0) {
        return "0.00"
      } else {
        return parseFloat(totalAmount).toFixed(2)
      }
    } else {
      return listCars && listCars.totalDiscountAmount ? parseFloat(listCars.totalDiscountAmount).toFixed(2) : "0.00"
    }
  }
  const totalNormalAmountFun = () => {
    if (props.modifyOrderDetailState) {
      let totalAmount = 0
      tableList.map(item => item.specialStatus != 1 ? totalAmount += (item.producePrice * item.orderQty) : "")
      if (totalAmount == 0) {
        return "0.00"
      } else {
        return parseFloat(totalAmount).toFixed(2)
      }
    } else {
      return listCars && listCars.normalAmt ? parseFloat(listCars.normalAmt).toFixed(2) : "0.00"
    }
  }



  const totalNormalDiscAmtAmountFun = () => {
    if (props.modifyOrderDetailState) {

      let totalAmount = totalNormalAmountFun() * listCars.clientDisc * 0.01
      if (totalAmount == 0) {
        return "0.00"
      } else {
        return totalAmount.toFixed(2)
      }
    } else {
      return listCars && listCars.normalDiscAmt ? parseFloat(listCars.normalDiscAmt).toFixed(1) : "0.00"
    }
  }
  return (
    <Modal
      centered={true}
      title={intl.get("OrderDetails")}
      open={props.orderDetailModal}
      footer={null}
      onCancel={props.handleCancel}
      width={1100}
    >
      <div className='divider_style' />
      {props.loading == true ? <div className='loading_myOrder'><Spin size="large" /></div> :
        <div>
          <div className='order_info'>
            <div>
              <div className='font-size-16'>{intl.get("OrderInformation")}</div>
              <div className='order_detail_border'>
                <div>
                  <div className='font-size-20'>{props.orderDetailList.orderNo}</div>
                  <div className='font-size-16 color-grey'>{props.orderDetailList.createDateFortmat}</div>
                </div>
                <div>
                  <div className='font-size-16'>{intl.get("AmountPayable")}</div>
                  <div className='font-size-20 color-blue'>$ {props.orderDetailList.amtValue}</div>
                </div>
                <div className='background-color-grey OrderPosition'>
                  <div className='font-size-16'>{orderDetail ? props.payType[orderDetail.payType] : ""}</div>
                  <div className={payStatus === 1 ? "font-size-20" : "font-size-20 color-red"}>
                    {props.payStatus[props.orderDetailList.payStatus]}</div>
                </div>
              </div>
            </div>
            <div>
              <div className='font-size-16'>{intl.get("deliveryAddress")}</div>
              <div className='order_detail_border'>
                <div>
                  <div className='font-size-20'>
                    <div>{props.orderDetailList.contactName}</div>
                    <div>{props.orderDetailList.contactTel}</div>
                  </div>
                  <div
                    style={{ display: listCars.payStatus == 0 && listCars.sendGoodsStatus == 0 ? "" : "none" }}
                    onClick={() => props.onUseAnotherAddressHandle()}
                    className='font-size-20 blue_text' >
                    {intl.get('modify')}
                  </div>
                </div>
                <div>
                  <div className='font-size-20 color-grey'>{props.orderDetailList.address}</div>
                </div>
                <div className='background-color-grey'>
                  <div className='font-size-16'>{intl.get('ShippingStatus')}</div>
                  <div className='font-size-20'
                    style={{
                      color: props.orderDetailList.sendGoodsStatus == 0 ? "rgba(0,0,0,0.45)"
                        : props.orderDetailList.sendGoodsStatus == 1 ? "#2E3092"
                          : props.orderDetailList.sendGoodsStatus == 3 ? "#EE1D23" : ""
                    }}>{props.deliveryStatus[props.orderDetailList.sendGoodsStatus]}</div>
                </div>
              </div>
            </div>

          </div>
          <div>
            <div
              className='personalCentre_tabs'>
              <div className='font-size-16'>{intl.get("productList")}</div>
              <div style={{ display: listCars.payStatus == 1 || listCars.sendGoodsStatus == 1 ? "" : "none" }}>
                <span className='text_grey'>已付和已接單訂單需修改請聯繫</span>
                <span className='color-red'>853-28271603</span>
              </div>
            </div>

            <Table
              rowKey={(data) => data.keyId}
              scroll={{ y: 600 }}
              columns={columns}
              pagination={false}
              dataSource={tableList}
            />
            {/* <div className='conclude_sty'>
              <div>
                <div className='number_goods'>
                  <div className='font-size-16'>{intl.get("NumberItems")}</div><div className='font-size-20 color-grey'>{props.orderDetailList.prodQty}</div>
                </div>
                <div>
                  <div className='font-size-16'>{intl.get("orderAmount")}</div><div className='font-size-20 color-grey'>
                    $ {orderDetail ? orderDetail.poValue : 0}</div>
                </div>
              </div>
              <div><div className='font-size-16'>{intl.get("discountAmount")}</div><div className='font-size-20'>
                -$ {orderDetail ? (orderDetail.poValue - orderDetail.amtValue).toFixed(2) : 0}</div> </div>
              <div><div className='font-size-16'>{intl.get("AmountPayable")}</div><div className='font-size-20 color-red'>
                $ {orderDetail ? orderDetail.amtValue : 0}</div></div>
            </div> */}

          </div>

        </div>
      }
      <div className='order_detail_foot'>
        <div style={{ width: "500px" }} className="myOrder_operation fontSize-16">
          <div>
            {intl.get("orderRemark")}
          </div>
          <div>
            {listCars.payStatus == 0 && listCars.sendGoodsStatus == 0 ?
              <TextArea
                value={props.orderDetailList.orderRemark}
                onChange={(e) => props.setOrderRemark(e.target.value)}
                style={{ backgroundColor: "#f2f2f2", border: "#f2f2f2" }}
                placeholder={intl.get("PleaseEnterNoteForYourOrder")}
                autoSize={{
                  minRows: 4,
                  maxRows: 4,
                }} />
              : props.orderDetailList.orderRemark}
          </div>
        </div>
        <div className="order_detail_total_sty">

          <div>
            <div>{intl.get("NumberItems")}<span className="font_color_grey">{tableList.length}</span></div>
            <div>{intl.get("specialOfferTotal")}
              <span className="font_color_red">
                <span>{props.symbol}</span>
                {totalDiscountAmountFun()}
              </span></div>
            <div>{intl.get("regularTotal")}
              <span className="font_color_grey"><span>{props.symbol}</span>
                {totalNormalAmountFun()}</span></div>
          </div>
          <div>
            <div>
              {intl.get("regularItemDiscount")}(-{listCars && listCars.clientDisc >= 0 ? listCars.clientDisc : "0"}%)
              <span className="offer_amount">
                <span>{props.symbol}</span>
                {totalNormalDiscAmtAmountFun()}
              </span>
            </div>
          </div>
          <div>
            <div>
              {intl.get("orderAmount")}
              <span className="font_color_blue">
                <span>{props.symbol}</span>{
                  // listCars && listCars.amtValue ? listCars.amtValue.toFixed(2) : "0.00"
                  totalAmountFun()
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='operation_style operation_button_style font-size-16'>
        <div
          onClick={() => props.cancelOrderFun(listCars)}
          style={{ display: listCars.payStatus == 0 && listCars.sendGoodsStatus == 0 ? "" : "none" }}
          className='red_border_text'>{intl.get("OrderCancellation")}</div>
        <div
          onClick={() => props.submitUpdateOrder(totalAmountFun())}
          style={{ display: props.modifyOrderDetailState ? "" : "none" }}
          className='blue_border_text'>{intl.get("UpdateOrder")}</div>

        <div
          onClick={() => props.switchPayQRcodeState(props)}
          style={{ display: listCars.payStatus == 0 && listCars.sendGoodsStatus !== 3 ? "" : "none" }}
          className='darkerBlue_text'>{intl.get("payment")}</div>

      </div>
      {
        //做更新按钮
      }
    </Modal>
  )
}
