import React, { Component } from "react";
import { Select, Col, Row, Input } from 'antd'
import intl from "react-intl-universal";
import { loadLocales } from "@/utils/HandlerLocales";

let pageContext

export default class forgottenPassword extends Component {
  constructor(props) {
    super(props);
    loadLocales('forgottenPassword')
  }
  render() {
    return (
      <div>
        <div style={{ marginTop: "20px" }} className="container">
          <div className="login_title_sty">{intl.get("forgottenPassword")}</div>
          <div className="login_sty">
            <form className="theme-form" >
              <Row gutter={12}>


                <Col span={12}>
                  <div className="login_form_group">
                    <label htmlFor="phone">{intl.get("phone")}</label>
                    <div className="region_select">
                      <div className="region">
                        <Select
                          defaultValue="853"
                          bordered={false}
                          style={{ width: "120px" }}
                          onChange={this.props.onSelectCountryCode}
                          options={[
                            { value: '853', label: intl.get("macao") },
                            { value: '852', label: intl.get("hongkong") },
                            { value: '86', label: intl.get("china") },
                          ]}
                        />
                      </div>

                      <Input
                        style={{ '--ant-input-active-border-color': '#2e3092' }}
                        className="phone_inputs"
                        id="mobilePhoneNumber"
                        placeholder={intl.get("phoneNumber")}
                        value={this.props.mobilePhoneNumber || ""}
                        onChange={e => this.props.onInputPhoneChange(e)}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="login_form_group">
                    <label htmlFor="email">{intl.get("newLoginPassword")}</label>
                    <div>
                      <Input.Password
                        style={{ '--ant-input-active-border-color': '#2e3092' }}
                        type="password"
                        className="fname_inputs"
                        id="pssword"
                        placeholder={intl.get("newLoginPassword")}
                        required=""
                        onChange={e => this.props.onInputLoginPasswordChange(e)}
                      />
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className="login_form_group">
                    <label htmlFor="review">{intl.get("captcha")}</label>
                    <div className="captcha_sty">
                      <Input
                        style={{ '--ant-input-active-border-color': '#2e3092' }}
                        type="text"
                        className="captcha_inputs"
                        id="verificationCode"
                        placeholder={intl.get("pleaseEnterTheCAPTCHA")}
                        required=""
                        value={this.props.verificationCode || ""}
                        onChange={e => this.props.onInputCaptchaChange(e)}
                      />
                      {this.props.loading ? (
                        <div className="captcha_btn_time">
                          {this.props.yztime}
                        </div>
                      ) : (
                        <div
                          onClick={this.props.smsSend}
                          className="captcha_btn"
                        >
                          {intl.get("SendCAPTCHA")}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="login_form_group">
                    <label htmlFor="review">{intl.get("ConfirmNewPassword")}</label>
                    <div>
                      <Input.Password
                        style={{ '--ant-input-active-border-color': '#2e3092' }}
                        type="password"
                        className="fname_inputs"
                        id="confirmPassword"
                        placeholder={intl.get("ConfirmNewPassword")}
                        required=""
                        onChange={e => this.props.onInputConfirmPasswordChange(e)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <div className="col-md-6">
                          <label htmlFor="review">姓</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lname"
                            placeholder="姓"
                            required=""
                            value={lname}
                            onChange={e => this.onInputLnameChange(e)}
                          />
                        </div> */}
              <div
                onClick={this.props.handleOnOK}
                className="create_account_btn"
              >
                {intl.get("ResetPassword")}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


